import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import Api from "./../../utils/Api";
import dayjs from "dayjs";
import { isToday, convertToFormData, addOneWeek } from "../../utils/ProgUtils";

var isBetween = require("dayjs/plugin/isBetween");
var isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);

// THUNKS

export const getEvents = createAsyncThunk("events/getEvents", async () => {
  try {
    const resp = await Api.get("events/");
    console.log("GET EVENTS SUCCESS", resp.data);
    return resp.data;
  } catch (err) {
    console.log("GET EVENTS ERROR", err);
  }
});

export const createEvent = createAsyncThunk(
  "events/createEvent",
  async (newEvent, { rejectWithValue }) => {
    // const state = getState();
    const formData = convertToFormData(newEvent);

    const headers = {
      headers: {
        // accept: "application/json",
        // "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": "multipart/form-data",
      },
    };

    try {
      const resp = await Api.post("events/", formData, {
        ...headers,
        withCredentials: true,
      });
      console.log("CREATE NEW EVENT SUCCESS", resp);
      return resp.data;
    } catch (err) {
      console.log("CREATE NEW EVENT ERROR", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateEvent = createAsyncThunk(
  "events/updateEvent",
  async (updatedEvent, { rejectWithValue }) => {
    const formData = convertToFormData(updatedEvent);

    const headers = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      const resp = await Api.patch(`events/${updatedEvent.id}`, formData, {
        ...headers,
        withCredentials: true,
      });
      console.log("UPDATE EVENT SUCCESS", resp);
      return resp.data;
    } catch (err) {
      console.log("UPDATE EVENT ERROR", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteEvent = createAsyncThunk(
  "events/deleteEvent",
  async (eventId) => {
    try {
      const resp = await Api.delete(`events/${eventId}`);
      console.log("DELETE EVENT SUCCESS", resp);
      return resp.data.eventId;
    } catch (err) {
      console.log("DELETE EVENT ERROR", err);
    }
  }
);

export const cancelEvent = createAsyncThunk(
  "events/cancelEvent",
  async (id) => {
    try {
      const resp = await Api.post(`events/cancel_event`, { id });
      console.log("CANCEL SUCCESS", resp);
      return resp.data.eventId;
    } catch (err) {
      console.log("CANCEL EVENT ERROR", err);
    }
  }
);

export const editPlusOneWeek = (eventId) => (dispatch, getState) => {
  const state = getState();
  const event = selectEventById(state, eventId);

  if (event) {
    const newStartTime = addOneWeek(event.startTime);
    const newEndTime = addOneWeek(event.endTime);

    const updatedEvent = {
      ...event,
      startTime: newStartTime,
      endTime: newEndTime,
    };

    dispatch(updateEvent(updatedEvent));
  }
};

export const makeToday = (eventId) => (dispatch, getState) => {
  const state = getState();
  const event = selectEventById(state, eventId);

  if (event) {
    const today = dayjs();
    const currentStartTime = event.startTime;
    const currentEndTime = event.endTime;

    // Set today's date while keeping the time from the original event
    const newStartTime = dayjs(currentStartTime)
      .set("year", today.year())
      .set("month", today.month())
      .set("date", today.date())
      .format();
    const newEndTime = dayjs(currentEndTime)
      .set("year", today.year())
      .set("month", today.month())
      .set("date", today.date())
      .format();

    const updatedEvent = {
      ...event,
      startTime: newStartTime,
      endTime: newEndTime,
    };

    dispatch(updateEvent(updatedEvent));
  }
};

// REDUCERS

const eventsAdapter = createEntityAdapter({
  selectId: (event) => event.id, // this is kinda defaulty, unneeded spec
});

// By default, `createEntityAdapter` gives you `{ ids: [], entities: {} }`.
// If you want to track 'loading' or other keys, you would initialize them here:
// `getInitialState({ loading: false, activeRequestId: null })`
const initialState = eventsAdapter.getInitialState({
  status: "idle",
});

export const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    // doSomethingEvent: () => {},
    // See comment in EventFeedView
    // setEvents: {
    //   reducer(state, action) {
    //     const updatedEvents = action.payload;
    //     state.events = updatedEvents;
    //   },
    //   prepare(updatedEvents) {
    //     return {
    //       payload: updatedEvents,
    //     };
    //   },
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEvents.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getEvents.fulfilled, (state, action) => {
        eventsAdapter.setAll(state, action.payload?.events || []);
        state.status = "idle";
      })
      .addCase(createEvent.fulfilled, eventsAdapter.addOne)
      .addCase(updateEvent.fulfilled, (state, { payload }) => {
        const { id, ...changes } = payload.event; // "changes" is a REQUIRED KEY for the update object, god
        eventsAdapter.updateOne(state, { id, changes });
      })
      .addCase(deleteEvent.fulfilled, eventsAdapter.removeOne);
  },
});

// ACTION CREATORS

// Action creators are generated for each case reducer function
export const { eventAdded, eventUpdated, editEvent, eventDeleted } =
  eventsSlice.actions;

export default eventsSlice.reducer;

// SELECTORS

export const selectEventEntities = (state) => state.events.entities;

export const selectEvents = createSelector(selectEventEntities, (entities) =>
  Object.values(entities)
);

export const selectEventById = (state, eventId) => {
  return selectEventEntities(state)[eventId];
};

export const selectEventIds = createSelector(
  // First, pass one or more "input selector" functions:
  selectEvents,
  // Then, an "output selector" that receives all the input results as arguments
  // and returns a final result value
  (events) => events.map((event) => event.id)
);

export const selectEventsOfUser = createSelector(
  // First input selector: all events
  selectEvents,
  // Second input selector: currentUser or provided userId
  (state, userId) =>
    userId || (state.users.currentUser ? state.users.currentUser.id : null),
  // Output selector: receives both values
  (events, userId) => {
    return events.filter((event) => event.userId === userId);
  }
);

export const selectTodaysEventsIds = createSelector(selectEvents, (events) => {
  const todaysEvents = events.filter((event) => isToday(event.startTime));
  return todaysEvents.map((event) => event.id);
});

export const selectMajorEventIds = createSelector(selectEvents, (events) => {
  const majorEvents = events.filter(
    (event) => event.isMajor && dayjs(event.endTime).isSameOrAfter(dayjs())
  );
  return majorEvents.map((event) => event.id);
});

// gets events that are upcoming in the next 7 days, but not today's events
export const selectUpcomingEventsIds = createSelector(
  selectEvents,
  (events) => {
    const today = dayjs();
    const nextWeek = today.add(1, "week");
    const upcomingEvents = events.filter((event) => {
      const eventDate = dayjs(event.startTime);
      return eventDate.isAfter(today) && eventDate.isBefore(nextWeek);
    });

    return upcomingEvents.map((event) => event.id);
  }
);

export const selectPastEventsIds = createSelector(selectEvents, (events) => {
  const today = dayjs();
  const pastEvents = events.filter((event) => {
    return dayjs(event.startTime).isBefore(today);
  });

  return pastEvents.map((event) => event.id);
});

export const selectFilteredEvents = createSelector(
  // First input selector: all events
  (state) => selectEventEntities(state),
  // Second input selector: all filter values
  (state) => state.filters,
  // Can i do a third??? Yes, yes I can
  (state) => state.users,
  // Output selector: receives both values
  (events, filters, users) => {
    const { locations, isFree, showMyEvents, futureDays } = filters;
    const userId = users.currentUser?.id;
    // const showAllCompletions = status === StatusFilters.All
    // if (showAllCompletions && colors.length === 0) {
    //   return todos
    // }

    const realEvents = Object.values(events); // this is a weird way of doing it, too much dumb object manipulation

    // const completedStatus = status === StatusFilters.Completed
    // Return either active or completed todos based on filter
    return realEvents.filter((event) => {
      const matchesSingleDay =
        dayjs(event.startTime).format("D/M/YY") ===
        dayjs().add(futureDays, "days").format("D/M/YY");

      // possible gray area between isMajor and multiday. Can adjust later
      const matchesMultiDay =
        event.isMajor &&
        dayjs(dayjs().add(futureDays, "days")).isBetween(
          event.startTime,
          dayjs(event.endTime),
          "day",
          "[]" // inclusive of start and end dates
        );

      const isFreeMatches = !!isFree ? !parseInt(event.coverCharge) : true;
      const locationMatches =
        locations.length === 0 || locations.includes(event.city);
      const isMyEventMatches = !!showMyEvents ? event.userId === userId : true;
      const isOnSelectedDateMatches = matchesSingleDay || matchesMultiDay;
      return (
        locationMatches &&
        isFreeMatches &&
        isMyEventMatches &&
        isOnSelectedDateMatches
      );
    });
  }
);

export const selectFilteredEventIds = createSelector(
  // Pass our oher memoized selector as an input
  selectFilteredEvents,
  // And derive data in the output selector
  (filteredEvents) => filteredEvents.map((event) => event.id)
);

export const selectEventIdsSortedByStartTime = createSelector(
  // First input selector: the array of event IDs
  (state, eventIds) => eventIds,
  // Second input selector: all events
  selectEventEntities,
  // Output selector: receives both values
  (eventIds, eventEntities) => {
    // Sort event IDs based on the start time of the events
    const sortedEventIds = eventIds.sort((a, b) =>
      eventEntities[a].startTime > eventEntities[b].startTime ? 1 : -1
    );
    return sortedEventIds;
  }
);

export const selectFilteredEventIdsbyCity = createSelector(
  selectFilteredEvents,
  (filteredEvents) => groupEventsByCity(filteredEvents)
);

// HELPER FUNCTIONS

const sortEventsByStartTime = (events) =>
  events.sort((a, b) => (a.startTime > b.startTime ? 1 : -1));

function groupEventsByCity(events) {
  // Sort is performed before grouping. Keep an eye on this.
  const eventsByCity = sortEventsByStartTime(events).reduce((acc, event) => {
    if (acc[event.city]) {
      acc[event.city].push(event.id);
    } else {
      acc[event.city] = [event.id];
    }
    return acc;
  }, {});

  return eventsByCity;
}
