import React, { useState, useEffect } from "react";
import { selectEventsOfUser } from "../../../features/events/eventsSlice";
import { useParams } from "react-router-dom";
import { getUserData } from "../../../features/users/usersSlice";
import { useSelector } from "react-redux";
import LabelView from "../../atoms/LabelView/LabelView";
import EventListView from "../../organisms/EventListView/EventListView";

export default function AdminHostProfileView(props) {
  const [userData, setUserData] = useState({});
  const params = useParams();
  const userId = Number(params.id);

  useEffect(() => {
    getUserData(userId)
      .then((userData) => {
        setUserData(userData);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [userId]);

  const eventsOfUser = useSelector((state) =>
    selectEventsOfUser(state, userId)
  );

  return (
    <div style={styles.container}>
      <h3>Admin Member View</h3>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <LabelView text={`Name: ${userData.name}`} />
          <LabelView text={`Email: ${userData.email}`} />
        </div>
        <div>
          <LabelView text={`Total Events: ${eventsOfUser.length}`} />
          <LabelView text={`WA: ${userData.whatsappNumber}`} />
        </div>
      </div>

      <EventListView eventsOfUser={eventsOfUser} />
    </div>
  );
}

const styles = {
  container: {
    padding: 16,
  },
  eventsContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 600,
  },
};
