import { combineReducers } from "redux";

import eventsReducer from "../features/events/eventsSlice";
import filtersReducer from "../features/filters/filtersSlice";
import usersReducer from "../features/users/usersSlice";
import hostsReducer from "../features/hosts/hostsSlice";

const rootReducer = combineReducers({
  // Define a top-level state field named `events`, handled by `eventsReducer`
  events: eventsReducer,
  filters: filtersReducer,
  users: usersReducer,
  hosts: hostsReducer,
});

export default rootReducer;
