import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useOutletContext } from "react-router-dom";
import ButtonView from "../../atoms/ButtonView/ButtonView";
import LabelView from "../../atoms/LabelView/LabelView";
import {
  getAllHosts,
  sendHostConfirmation,
} from "../../../features/users/usersSlice";
import { blackColor, muiColors } from "../../../utils/globalStyles";

export default function AdminHostsView() {
  const [allHosts, setAllHosts] = useState([]);
  const [selectedButton, setSelectedButton] = useState("requested");

  const dispatch = useDispatch();
  const { isMobile } = useOutletContext();

  useEffect(() => {
    dispatch(getAllHosts())
      .then((resp) => {
        setAllHosts(resp.payload.allHosts); // this is not an action creator. this should not have a payload
      })
      .catch((err) => console.log(err));
  }, [dispatch]);

  const handleHostConfirmationClick = async (isHostConfirmed, hostUserId) => {
    dispatch(sendHostConfirmation({ isHostConfirmed, hostUserId })).then(
      (resp) => {
        setAllHosts(resp.payload.allHosts);
      }
    );
  };

  const buttonCategories = [
    { key: "requested", label: "Requested" },
    { key: "rejected", label: "Rejected" },
    { key: "confirmed", label: "Confirmed" },
  ];

  const getCounts = () => {
    const counts = {};

    buttonCategories.forEach(({ key }) => {
      counts[key] = allHosts.filter((host) => filterByStatus(host, key)).length;
    });

    return counts;
  };

  const filterByStatus = (host, category) => {
    switch (category) {
      case "requested":
        return (
          host.isHostRequested && !host.isHostConfirmed && !host.isHostRejected
        );
      case "rejected":
        return host.isHostRejected;
      case "confirmed":
        return host.isHostConfirmed;
      default:
        return false;
    }
  };

  const counts = getCounts();
  const filteredHosts = allHosts.filter((host) =>
    filterByStatus(host, selectedButton)
  );

  const dynamicContainer = {
    ...styles.container,
    width: isMobile ? undefined : 700,
  };

  return (
    <div style={dynamicContainer}>
      <div style={{ display: "flex", marginBottom: 12 }}>
        {buttonCategories.map(({ key, label }) => (
          <ButtonView
            key={key}
            text={`${label} (${counts[key]})`}
            variant="contained"
            sx={{ ml: 1, mr: 1 }}
            color={selectedButton === key ? muiColors.primary : muiColors.black}
            onClick={() => setSelectedButton(key)}
          />
        ))}
      </div>
      <div>
        {filteredHosts.map((request) => (
          <div style={styles.hostContainer} key={request.email}>
            <div>
              <LabelView text={`Name: ${request.name}`} />
              <LabelView text={`Email: ${request.email}`} />
              <LabelView text={`Whatsapp: ${request.whatsappNumber}`} />
              <LabelView text={`Bio: ${request.bio}`} />
            </div>

            {selectedButton === "requested" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                }}
              >
                <ButtonView
                  text="approve"
                  variant="contained"
                  sx={{ m: 1 }}
                  onClick={() => handleHostConfirmationClick(true, request.id)}
                />
                <ButtonView
                  text="reject"
                  variant="contained"
                  sx={{ m: 1 }}
                  onClick={() => handleHostConfirmationClick(false, request.id)}
                  color={muiColors.error}
                />
              </div>
            )}

            {selectedButton === "confirmed" && (
              <ButtonView
                text="Profile"
                variant="contained"
                sx={{ ml: 2 }}
                to={`/admin/hostProfile/${request.id}`}
                color={muiColors.primary}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

const styles = {
  // This container style could possibly be moved up one level? Using same for EventFeedView
  container: {
    padding: 16,
  },
  hostContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${blackColor}`,
    paddingBottom: 12,
    marginBottom: 12,
    // width: 700,
  },
};
