import { common } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import { paletteRed } from "./globalStyles";

export const theme = createTheme({
  palette: {
    // primary: {
    //   main: purple[500],
    // },
    // secondary: {
    //   main: green[500],
    // },
    black: {
      main: common.black,
      contrastText: common.white,
    },
    paletteRed: {
      main: paletteRed,
      contrastText: common.white,
    },
    // success: {
    //   main: green[800], // uses #2e7d32, which is default "success" color
    // },
  },

  // textField: {
  //   color: "pink",
  //   background: "green",
  // },

  components: {
    // MuiTextField: {
    //   root: { color: "green" },
    // },

    MuiOutlinedInput: {
      styleOverrides: {
        // color: "red",
        border: "1px solid green",
        input: {
          // color: "red",
          // '&::before': {
          //     border: "1px solid rgba(255, 0, 0, 0.42)" // use your color
          // }
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        color: "red",
        input: {
          // color: "white",
          // '&::before': {
          //     border: "1px solid rgba(255, 0, 0, 0.42)" // use your color
          // }
        },
      },
    },
  },
});
