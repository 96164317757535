import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

export default function LoadingSpinnerView(props) {
  const { isLoading, size = 45, style = {}, color = "primary" } = props;

  return (
    <div style={style}>
      {isLoading && <CircularProgress size={size} sx={{ color: color }} />}
    </div>
  );
}
