import { TextField } from "@mui/material";
import React, { useState } from "react";
import ButtonView from "../../atoms/ButtonView/ButtonView";
import { useSelector, useDispatch } from "react-redux";
import { updateUserDetails } from "../../../features/users/usersSlice";
import { validatePassword } from "../../../utils/ProgUtils";
import { useOutletContext, useNavigate } from "react-router-dom";
import LabelView from "../../atoms/LabelView/LabelView";
import { muiColors, redColor } from "../../../utils/globalStyles";

const userDetailFields = [
  { id: "firstName", title: "First Name" },
  { id: "lastName", title: "Last Name" },
  // { id: "email", title: "Email" },
  { id: "password", title: "New Password" },
  { id: "confirmPassword", title: "Confirm New Password" },
  {
    id: "whatsappNumber",
    title: "Whatsapp # (Incl. Country Code)",
    placeholder: "+62XXXXXXXX",
  },
  {
    id: "instagramUniqueId",
    title: "Instagram Handle",
    placeholder: "danceinbali",
  },
  {
    id: "telegramUrl",
    title: "Telegram URL",
    placeholder: "https://t.me/dance_in_bali",
  },
  {
    id: "facebookUrl",
    title: "Facebook URL",
    placeholder: "https://www.facebook.com/danceinbali",
  },
  {
    id: "websiteUrl",
    title: "Website URL",
    placeholder: "https://danceinbali.com",
  },
  { id: "bio", title: "Bio" },
  { id: "userImage", title: "Profile Pic" },

  // { id: "requestHost", title: "Request Host?" },
];

export default function SettingsView(props) {
  const { isMobile } = useOutletContext();
  const currentUser = useSelector((state) => state.users.currentUser);

  const originalDetails = {
    id: currentUser.id,
    firstName: currentUser.name.split(" ")[0],
    lastName: currentUser.name.split(" ")[1],
    email: currentUser.email, // submitted, has implications with get_current_user. I need to decide on a way to define instance variables. e.g. @user locally or @current_user globally
    password: "",
    confirmPassword: "",
    bio: currentUser.bio,
    whatsappNumber: currentUser.whatsappNumber,
    instagramUniqueId: currentUser.instagramUniqueId,
    telegramUrl: currentUser.telegramUrl,
    facebookUrl: currentUser.facebookUrl,
    websiteUrl: currentUser.websiteUrl,
    // role: "",
  };

  const [userDetails, setUserDetails] = useState(originalDetails);

  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedPanel, setSelectedPanel] = useState("details");

  const buttonDetails = [
    { id: "details", text: "Change Profile" },
    { id: "password", text: "Change Password" },
  ];

  const onPanelSelect = (id) => {
    setSelectedPanel(id);
    setUserDetails(originalDetails);
  };

  const buttonViews = () => {
    return buttonDetails.map((input) => (
      <ButtonView
        key={input.id}
        text={input.text}
        variant="contained"
        color={selectedPanel === input.id ? muiColors.primary : muiColors.black}
        onClick={() => onPanelSelect(input.id)}
        sx={{ mr: 2 }}
        size={isMobile ? "small" : undefined}
      />
    ));
  };

  const dynamicInput = {
    ...styles.input,
    width: isMobile ? "90%" : 400,
  };

  const onImageChange = (e) =>
    setUserDetails({ ...userDetails, userImage: e.target.files[0] });
  const userDetailInput = ({ title, id, placeholder }) => {
    switch (id) {
      case "bio":
        return (
          <div key={id}>
            <TextField
              label={title}
              variant="outlined"
              sx={{ ...dynamicInput, width: isMobile ? "90%" : 600 }}
              value={userDetails[id]}
              onChange={(e) =>
                setUserDetails({ ...userDetails, [id]: e.target.value })
              }
              multiline
              rows={12}
            />
          </div>
        );
      case "userImage":
        return (
          <div key={id}>
            <input
              type="file"
              accept="image/png, image/jpeg, image/webp"
              multiple={false}
              onChange={(e) => onImageChange(e)}
              style={{ marginLeft: 4 }}
            />
            {!userDetails.userImage && (
              <LabelView text="Profile Pic JPEG/PNG/WEBP, max 2MB" />
            )}
            {userDetails.userImage && (
              <img
                alt="User Prolife"
                src={userDetails.userImage}
                height={120}
                style={{ objectfit: "contain", color: "rgba(0,0,0,0)" }}
              />
            )}
          </div>
        );
      default:
        return (
          <div key={id}>
            <TextField
              label={title}
              variant="outlined"
              placeholder={placeholder}
              type={
                ["password", "confirmPassword"].includes(id)
                  ? "password"
                  : undefined
              }
              required={["firstName"].includes(id)}
              sx={dynamicInput}
              value={userDetails[id]}
              onChange={(e) =>
                setUserDetails({ ...userDetails, [id]: e.target.value })
              }
            />
          </div>
        );
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // do I need this?

    // I need to decide if i want to format these params here or in the thunk
    const {
      id,
      firstName = "",
      lastName = "",
      email = "",
      password = "",
      confirmPassword = "",
      bio = "",
      whatsappNumber = "",
      instagramUniqueId = "",
      telegramUrl = "",
      facebookUrl = "",
      websiteUrl = "",
      userImage,
    } = userDetails;

    let updatedUserDetails = {
      id,
      name: `${firstName} ${!!lastName ? lastName : ""}`.trim(),
      email,
      password,
      confirmPassword,
      whatsappNumber,
      bio,
      instagramUniqueId,
      telegramUrl,
      facebookUrl,
      websiteUrl,
      userImage,
      // role: role,
    };

    const validResp = validatePassword(password, confirmPassword, email);

    if (validResp.isValid) {
      try {
        const resp = await dispatch(
          updateUserDetails(updatedUserDetails)
        ).unwrap();
        console.log("USER DETAILS UPDATE SUCESS", resp);
        setErrorMessage("");
        alert("User details updated successfully!");
        navigate("/");
      } catch (err) {
        console.log("USER DETAILS UPDATE ERROR", err);
        setErrorMessage(err.errors[0]);
      }
    } else {
      setErrorMessage(validResp.errorMessage);
    }
  };

  const filterFields = () => {
    const settingsPages = {
      password: ["password", "confirmPassword"],
      details: ["firstName", "lastName"],
      hostDetails: [
        "whatsappNumber",
        "bio",
        "userImage",
        "instagramUniqueId",
        "telegramUrl",
        "facebookUrl",
        "websiteUrl",
      ],
    };

    let selectedFields = settingsPages[selectedPanel];

    if (
      (currentUser.role === "host" || currentUser.role === "admin") &&
      selectedPanel === "details"
    )
      selectedFields = selectedFields.concat(settingsPages.hostDetails);

    return userDetailFields.filter((field) =>
      selectedFields.includes(field.id)
    );
  };

  const isRequiredFieldsFilled = userDetails.firstName;

  return (
    <div style={styles.container}>
      <LabelView text="Settings" type="pageHeader" />
      <LabelView text={`Account Email: ${currentUser.email}`} />
      <div style={{ flex: 1, margin: "12px 0px" }}>{buttonViews()}</div>
      <div style={styles.inputsContainer}>
        {filterFields().map((field) => userDetailInput(field))}
      </div>
      {!!errorMessage && (
        <LabelView text={errorMessage} style={{ color: redColor }} />
      )}
      <div style={{ display: "flex", marginTop: 12 }}>
        <ButtonView
          text="Update"
          color={muiColors.success}
          variant="contained"
          onClick={handleSubmit}
          disabled={!isRequiredFieldsFilled}
        />
        <ButtonView
          text="Back"
          sx={{ ml: 2 }}
          color={muiColors.black}
          variant="contained"
          to="/"
        />
      </div>
    </div>
  );
}

const styles = {
  container: {
    padding: 16,
  },
  inputsContainer: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    marginTop: 12,
  },
  input: {
    m: 1,
    width: 300,
  },
};
