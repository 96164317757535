import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import ButtonView from "../../atoms/ButtonView/ButtonView";
import LabelView from "../../atoms/LabelView/LabelView";
import { useDispatch, useSelector } from "react-redux";
import {
  createEvent,
  selectEventById,
} from "../../../features/events/eventsSlice";
import { useOutletContext, useParams } from "react-router-dom";
// might just use <DatePicker/> might keep in the view. Gotta see how it interacts with this component's state
import DatePickerView from "../../atoms/DatePickerView/DatePickerView";
import { useNavigate } from "react-router-dom";
import {
  cityLocations,
  eventInputFields,
  NEW_EVENT_ONE,
} from "../../../utils/Constants";
import { muiColors, redColor } from "../../../utils/globalStyles";
import { selectIsAdmin } from "../../../utils/UserPermissions";
import { addOneWeek } from "../../../utils/ProgUtils";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

export default function CreateEventView(props) {
  const { isMobile } = useOutletContext();
  const navigate = useNavigate();
  const params = useParams();
  const prefillId = params.id;
  const prefillEvent = useSelector((state) =>
    selectEventById(state, prefillId)
  );
  const isAdmin = useSelector(selectIsAdmin);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    id: "",
    message: "",
  });

  const [eventDetails, setEventDetails] = useState({
    name: "",
    city: "",
    hosts: "",
    venue: "",
    locationLink: "",
    startTime: undefined,
    endTime: undefined,
    coverCharge: "",
    // promos: "",
    // currency: "IDR",
    // styles: "",
    musicProvider: "",
    description: "",
    isRecurring: false,
    isMajor: false,
    // hasClass: false,
  });

  useEffect(() => {
    // Bulk updating the event prevents form completion due to additional, sometimes null, attributes
    if (prefillId) {
      setEventDetails({
        name: prefillEvent.name,
        city: prefillEvent.city,
        hosts: prefillEvent.hosts,
        venue: prefillEvent.venue,
        locationLink: prefillEvent.locationLink,
        startTime: undefined,
        endTime: undefined,
        coverCharge: prefillEvent.coverCharge,
        // styles: prefillEvent.styles,
        musicProvider: prefillEvent.musicProvider,
        description: prefillEvent.description,
        eventImage: prefillEvent.eventImageUrl, // this is a naming exception
        prefillEventId: prefillId,
        isRecurring: prefillEvent.isRecurring,
        isMajor: prefillEvent.isMajor,
      });
    }
  }, [prefillId, prefillEvent]);

  const dispatch = useDispatch();

  // Create new eventDetails object that only includes editable info e.g. excluding id, createdAt, etc.
  const usedKeys = Object.keys(eventDetails);
  const editableEventDetails = {};
  const optionalEventDetails = [
    "hosts",
    "coverCharge",
    "musicProvider",
    "eventImageUrl",
    "prefillEventId",
    "isRecurring",
    "isMajor",
  ];
  usedKeys.forEach((key) => {
    // only gets eventImageUrl after image is uploaded to Cloudinary. Requiring this field to submit is a catch-22, so it is excluded from the check
    if (!optionalEventDetails.includes(key))
      editableEventDetails[key] = eventDetails[key];
  });

  // if any field is falsey, form is incomplete
  const isFormComplete = !Object.values(editableEventDetails).some(
    (detail) => !detail
  );

  const fillDefault = () => setEventDetails(NEW_EVENT_ONE);

  const onImageChange = (e) =>
    setEventDetails({ ...eventDetails, eventImage: e.target.files[0] });

  const dynamicInput = {
    ...styles.input,
    width: isMobile ? "90%" : 400,
  };

  const eventInput = ({ title, id }) => {
    switch (id) {
      case "startTime":
      case "endTime":
        // const minDate = id === "startTime" ? dayjs() : eventDetails.startTime;
        return (
          <DatePickerView
            key={id}
            label={title}
            // minDate={minDate}
            value={eventDetails[id]}
            onChange={(value) =>
              setEventDetails({ ...eventDetails, [id]: value?.$d })
            }
            sx={dynamicInput}
          />
        );
      case "city":
        // Not sure why the label isn't working properly. This is weird and dumb.
        return (
          <FormControl key={id}>
            <InputLabel style={{ marginTop: 8, marginLeft: 6 }}>
              City *
            </InputLabel>
            <Select
              label={"city"}
              variant="outlined"
              sx={dynamicInput}
              value={eventDetails[id]}
              onChange={(e) =>
                setEventDetails({ ...eventDetails, [id]: e.target.value })
              }
            >
              {cityLocations.map((location) => (
                <MenuItem key={location} value={location}>
                  {location}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case "description":
        return (
          <div key={id}>
            <TextField
              label={title}
              variant="outlined"
              required
              sx={{ ...dynamicInput, width: isMobile ? "90%" : 600 }}
              value={eventDetails[id]}
              onChange={(e) =>
                setEventDetails({ ...eventDetails, [id]: e.target.value })
              }
              multiline
              rows={12}
            />
          </div>
        );
      case "eventImage":
        return (
          <div key={id}>
            <input
              type="file"
              accept="image/png, image/jpeg, image/webp"
              multiple={false}
              onChange={(e) => onImageChange(e)}
            />
            {!eventDetails.eventImage && (
              <LabelView text="JPEG/PNG/WEBP, max 2MB" />
            )}
            {eventDetails.eventImage && (
              <img
                alt={"Event Poster"}
                src={eventDetails.eventImage}
                height={120}
                style={{ objectfit: "contain", color: "rgba(0,0,0,0)" }}
              />
            )}
          </div>
        );
      case "isRecurring":
        return (
          <FormControlLabel
            key={id}
            label={"Is this a weekly recurring event?"}
            disabled={eventDetails.isMajor}
            control={
              <Checkbox
                checked={eventDetails.isRecurring}
                onChange={(e) => {
                  if (!eventDetails.isMajor) {
                    setEventDetails({
                      ...eventDetails,
                      isRecurring: e.target.checked,
                    });
                  }
                }}
              />
            }
          />
        );
      case "isMajor":
        return (
          <FormControlLabel
            key={id}
            label="This is a special, multi-day event, like a conference"
            disabled={eventDetails.isRecurring}
            control={
              <Checkbox
                checked={eventDetails.isMajor}
                onChange={(e) => {
                  if (!eventDetails.isRecurring) {
                    setEventDetails({
                      ...eventDetails,
                      isMajor: e.target.checked,
                    });
                  }
                }}
              />
            }
          />
        );
      default:
        return (
          <div key={id}>
            <TextField
              label={title}
              variant="outlined"
              required={!["coverCharge", "musicProvider", "hosts"].includes(id)}
              sx={dynamicInput}
              value={eventDetails[id]}
              onChange={(e) =>
                setEventDetails({ ...eventDetails, [id]: e.target.value })
              }
            />
          </div>
        );
    }
  };

  const checkEndtime = () => {
    if (eventDetails.startTime && eventDetails.endTime) {
      if (eventDetails.startTime > eventDetails.endTime) {
        return false;
      }
    }
    return true;
  };

  // onCreate and onSubmit (EditEventView) are working but smelly with their async behavior.
  const onCreate = async () => {
    if (!checkEndtime()) {
      alert("End time cannot be before start time");
      return;
    }

    try {
      setIsLoading(true);
      const resp = await dispatch(createEvent(eventDetails)).unwrap();
      if (resp.result === "success") {
        alert("Event created successfully!");
        navigate("/");
      }
    } catch (err) {
      console.error("ON CREATE ERROR", err);
      setIsLoading(false);
      setErrorMessage({
        id: err.errorId,
        message: err.message,
      });
    }
  };

  // This will eventually be a better way to handle creating events
  // try {
  //   const resp = await dispatch(updateEvent(eventDetails));
  //   const updatedEvents = resp.payload.events;
  //   navigate("/", { state: { updatedEvents: updatedEvents } });
  // } catch (e) {
  //   console.error(e);
  // }

  const dynamicInputsContainer = {
    ...styles.inputsContainer,
    // setting a percentage is working in settingsView
    height: isMobile ? undefined : 660, // harcoding this height is annoying, but so are big input fields
    marginBottom: isMobile ? 12 : undefined,
  };

  const usedEventInputFields = eventDetails.isMajor
    ? eventInputFields.filter((field) => field.isMajor)
    : eventInputFields;

  return (
    <div style={styles.container}>
      <h3>Create Event</h3>
      {!!prefillId && (
        <LabelView
          style={{ marginBottom: 8 }}
          text={`Because you clicked the "PRE-FILL" button, all event details have been copied into this form EXCEPT FOR the start and end times. Check new details for accuracy, add a time, then click Create!`}
        />
      )}
      <div style={dynamicInputsContainer}>
        {usedEventInputFields.map((field) => eventInput(field))}
      </div>

      <div style={{ display: "flex" }}>
        <ButtonView
          text="Create Event"
          color={muiColors.success}
          variant="contained"
          onClick={onCreate}
          size={isMobile ? "small" : undefined}
          disabled={!isFormComplete || isLoading}
        />
        <ButtonView
          text="Back"
          sx={{ ml: 2 }}
          color={muiColors.black}
          variant="contained"
          to="/"
          size={isMobile ? "small" : undefined}
          disabled={isLoading}
        />
        {isAdmin && (
          <ButtonView
            text="fill default"
            onClick={fillDefault}
            variant="contained"
            sx={{ ml: 2 }}
            color={muiColors.black}
            size={isMobile ? "small" : undefined}
            disabled={isLoading}
          />
        )}
      </div>
      {!!errorMessage.id && (
        <LabelView text={errorMessage.message} style={{ color: redColor }} />
      )}
      {isLoading && <div>Sending data, please wait...</div>}
    </div>
  );
}

const styles = {
  container: {
    padding: 16,
  },
  inputsContainer: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
  },
  input: {
    m: 1,
  },
};
