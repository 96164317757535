export default function LabelView(props) {
  const { style } = props;

  // try to use few divs if possible
  // add some custom styling that doesnt revolve around headers
  return <div style={style}>{getText(props)}</div>;
}

function getText(props) {
  const { text, type, style, isBold = false } = props;

  const dynamicStyle = {
    ...styles[type],
    fontWeight: isBold ? "bold" : "normal",
    ...style,
  };

  // Can pass `text` as a whole html element if I want to do special formatting
  // Don't try to interpolate an html object as a template literal into the `text` string
  switch (type) {
    case "title":
      return <h1>{text}</h1>;
    case "smalltitle":
      return <h2>{text}</h2>;
    case "subtitle":
      return <h4>{text}</h4>;
    default:
      return (
        <span key={text} style={dynamicStyle}>
          {text}
        </span>
      );
  }
}

const styles = {
  body: {
    fontSize: 16,
  },
  bigBody: {
    fontSize: 16,
  },
  pageHeader: {
    fontSize: 20,
    fontWeight: 700,
  },
  // dashley: {
  //   fontFamily: "DashleyRegular",
  //   fontWeight: "normal",
  //   fontStyle: "normal",
  //   fontSize: 28,
  // },
  permanentMarker: {
    fontFamily: "Permanent Marker",
    fontWeight: "normal",
    fontStyle: "normal",
    fontSize: 28,
  },
  permanentMarkerSmall: {
    fontFamily: "Permanent Marker",
    fontWeight: "normal",
    fontStyle: "normal",
    fontSize: 16,
  },
  // cityTitle: {
  //   margin: 8,
  //   // marginBottom: 8,
  //   fontWeight: 800,
  //   boxShadow: "0px 10px 12px 5px black",
  //   borderRadius: 8,
  //   fontSize: 24,
  //   // color: "white",
  //   // background: "black",
  //   // padding: 8,
  // },
};
