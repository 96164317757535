// import Link from "@mui/material/Link";

// export default function LinkInternalView(props) {
//   const { to, text, style } = props;

//   return (
//     <Link href={to} style={style}>
//       {text}
//     </Link>
//   );
// }

// const style = {
//   cursor: "pointer",
// };

// This is a WIP
import { whiteColor } from "../../../utils/globalStyles";
import ButtonView from "../ButtonView/ButtonView";

export default function LinkInternalView(props) {
  const { id, to, text, variant, color, sx, onClick } = props;

  return (
    <ButtonView
      id={id}
      to={to}
      text={text}
      sx={{
        ...sx,
        color: whiteColor,
        height: 24,
        "&:hover": { textDecoration: "underline" },
      }}
      size="small"
      variant={variant}
      color={color}
      onClick={onClick}
    />
  );
}

// const style = {
//   cursor: "pointer",
//   boxShadow: undefined,
// };
