import Link from "@mui/material/Link";

export default function LinkView(props) {
  const { href, target, text } = props;

  return (
    <Link href={href} target={target} style={style}>
      {text}
    </Link>
  );
}

const style = {
  cursor: "pointer",
};
