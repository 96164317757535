import { TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import ButtonView from "../../atoms/ButtonView/ButtonView";
import { useSelector, useDispatch } from "react-redux";
import { attemptLogin } from "../../../features/users/usersSlice";
import { useNavigate } from "react-router-dom";
import LabelView from "../../atoms/LabelView/LabelView";
import { muiColors, redColor } from "../../../utils/globalStyles";

const loginDetailFields = [
  { id: "email", title: "Email" },
  { id: "password", title: "Password" },
];

export default function LoginView(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoggedIn } = useSelector((state) => state.users);

  useEffect(() => {
    if (isLoggedIn) navigate("/");
  }, [isLoggedIn, navigate]);

  const [loginDetails, setLoginDetails] = useState({
    email: "",
    password: "",
  });

  const [errorMessage, setErrorMessage] = useState({
    id: "",
    message: "",
  });

  const loginDetailInput = ({ title, id }) => (
    <div key={id}>
      <TextField
        id={id}
        label={title}
        // error={errorMessage.id === id}
        // helperText={errorMessage.id === id && errorMessage.message}
        variant="outlined"
        type={id === "password" ? "password" : undefined}
        sx={styles.input}
        value={loginDetails[id]}
        autoFocus={id === "email"}
        onChange={(e) =>
          setLoginDetails({ ...loginDetails, [id]: e.target.value })
        }
      />
    </div>
  );

  const onLoginAttempt = async () => {
    try {
      const resp = await dispatch(attemptLogin(loginDetails)).unwrap();
      if (resp.data?.loggedIn) {
        navigate("/");
      }
    } catch (err) {
      setErrorMessage({
        id: err.errorId,
        message: err.message,
      });
    }
  };

  return (
    <div style={styles.container}>
      <LabelView text="Log In" type="pageHeader" />
      <div style={styles.inputsContainer}>
        {loginDetailFields.map((field) => loginDetailInput(field))}
        {!!errorMessage.id && (
          <LabelView text={errorMessage.message} style={{ color: redColor }} />
        )}
      </div>

      <div style={{ display: "flex" }}>
        <ButtonView
          id="login-button"
          text="Log In"
          color={muiColors.success}
          variant="contained"
          onClick={onLoginAttempt}
        />
        <ButtonView
          text="Back"
          sx={{ ml: 2 }}
          color={muiColors.black}
          variant="contained"
          to="/"
        />
      </div>
    </div>
  );
}

const styles = {
  container: {
    padding: 16,
  },
  inputsContainer: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    height: "80%",
  },
  input: {
    m: 1,
    width: "300px",
  },
};
