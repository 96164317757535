import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import Api from "./../../utils/Api";

// THUNKS

export const getHosts = createAsyncThunk("hosts/getHosts", async () => {
  try {
    const resp = await Api.get("hosts/");
    console.log("GET HOSTS SUCCESS", resp.data);
    return resp.data;
  } catch (err) {
    console.log("GET HOSTS ERROR", err);
  }
});

// REDUCERS

// does not use initial key-value arg, unlike eventAdapter
const hostsAdapter = createEntityAdapter({});

// By default, `createEntityAdapter` gives you `{ ids: [], entities: {} }`.
// If you want to track 'loading' or other keys, you would initialize them here:
// `getInitialState({ loading: false, activeRequestId: null })`
const initialState = hostsAdapter.getInitialState({
  status: "idle",
});

export const hostsSlice = createSlice({
  name: "hosts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHosts.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getHosts.fulfilled, (state, action) => {
        hostsAdapter.setAll(state, action.payload?.users || []);
        state.status = "idle";
      });
  },
});

// ACTION CREATORS

// Action creators are generated for each case reducer function
// export const {} = hostsSlice.actions;

export default hostsSlice.reducer;

// SELECTORS

export const selectHostEntities = (state) => state.hosts.entities;

export const selectHostIds = (state) => state.hosts.ids;

export const selectHosts = createSelector(selectHostEntities, (entities) =>
  Object.values(entities)
);

export const selectHostById = (state, userId) => {
  return selectHostEntities(state)[userId];
};

// HELPER FUNCTIONS
