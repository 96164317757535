import axios from "axios";
import { camelizeKeys, decamelizeKeys } from "humps";
import { RUNTIME_ENVIRONMENT } from "./Environment";

// heroku-provided domain name: "https://bld-api-1b7010268c9c.herokuapp.com/"

const Api = axios.create({
  baseURL:
    RUNTIME_ENVIRONMENT === "prod"
      ? "https://data.danceinbali.com/"
      : "http://localhost:3000",
});

Api.defaults.withCredentials = true;

// Axios middleware to convert all api responses to camelCase
Api.interceptors.response.use((response) => {
  if (
    response.data &&
    response.headers["content-type"] === "application/json; charset=utf-8" // everything semicolon(inclusive) and to the right was added
  ) {
    response.data = camelizeKeys(response.data);
  }

  return response;
});

// Axios middleware to convert all api requests to snake_case
Api.interceptors.request.use((config) => {
  const newConfig = { ...config };
  newConfig.url = `api/${config.url}`;

  if (newConfig.headers["Content-Type"] === "multipart/form-data")
    return newConfig;

  if (config.params) {
    newConfig.params = decamelizeKeys(config.params);
  }

  if (config.data) {
    newConfig.data = decamelizeKeys(config.data);
  }

  return newConfig;
});

export default Api;
