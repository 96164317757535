import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useOutletContext } from "react-router-dom";
import ButtonView from "../../atoms/ButtonView/ButtonView";
import { TextField } from "@mui/material";
import { addUser } from "../../../features/users/usersSlice";
import { validatePassword } from "../../../utils/ProgUtils";
import { muiColors } from "../../../utils/globalStyles";
// import { useNavigate } from "react-router-dom";

export default function AdminAddHostView(props) {
  const dispatch = useDispatch();
  const { isMobile } = useOutletContext();
  // const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");
  const [hostDetails, setHostDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    whatsappNumber: "",
    // bio: "",
    role: "host",
    isHostRequested: true,
    isHostConfirmed: true,
  });

  // This function is basically copied out of SignUpView.js
  const handleAddHost = async (event) => {
    event.preventDefault();
    const {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      isHostRequested,
      isHostConfirmed,
      role,
      whatsappNumber,
      // bio,
    } = hostDetails;

    let formattedHostDetails = {
      name: `${firstName} ${lastName}`,
      email: email.toLowerCase(),
      password,
      confirmPassword,
      isHostConfirmed,
      role,
      isHostRequested,
      whatsappNumber,
      // bio,
    };

    const validResp = validatePassword(password, confirmPassword, email);

    if (validResp.isValid) {
      try {
        const resp = await dispatch(addUser(formattedHostDetails)).unwrap();
        setErrorMessage("");
        if (resp.status === "created") {
          console.log("ADMIN ADD USER SUCCSSS", resp);
          alert(resp.message);
          // navigate("/admin");
        }
      } catch (err) {
        console.log("ADMIN ADD USER ERROR", err);
        alert("Sorry, there was an error.", err.message);

        // Improve error handling here
        setErrorMessage(err.message);
        // setErrorMessage({
        //   id: err.errorId,
        //   message: err.message,
        // });
      }
    } else {
      setErrorMessage(validResp.errorMessage);
    }
  };

  const onChange = (e, id) =>
    setHostDetails({ ...hostDetails, [id]: e.target.value });

  const dynamicContainer = {
    ...styles.container,
    width: isMobile ? undefined : 500,
  };

  const inputDetails = [
    { id: "firstName", label: "First Name" },
    { id: "lastName", label: "Last Name" },
    { id: "email", label: "Email" },
    { id: "password", label: "Password" },
    { id: "confirmPassword", label: "Confirm Password" },
    { id: "whatsappNumber", label: "# Whatsapp (w/ country code. No '+')" },
    // { id: "bio", label: "Bio" },
  ];

  const textFields = () => {
    return inputDetails.map((input) => (
      <TextField
        key={input.id}
        id={input.id}
        label={input.label}
        onChange={(e) => onChange(e, input.id)}
        sx={{ mb: 1 }}
        // multiline={input.id === "bio"}
        // rows={input.id === "bio" ? 4 : undefined}
      />
    ));
  };

  return (
    <div style={dynamicContainer}>
      <div style={styles.inputsContainer}>
        {textFields()}
        {!!errorMessage && <div>{errorMessage}</div>}
        <ButtonView
          text="Add Host"
          onClick={handleAddHost}
          variant="contained"
          color={muiColors.primary}
        />
      </div>
    </div>
  );
}

const styles = {
  // This container style could possibly be moved up one level? Using same for EventFeedView
  container: {
    padding: 16,
  },
  inputsContainer: {
    display: "flex",
    flexDirection: "column",
  },
};
