import React from "react";
import ButtonView from "../atoms/ButtonView/ButtonView";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { muiColors } from "../../utils/globalStyles";

export default function BackArrowButton(props) {
  const { sx } = props;
  return (
    <ButtonView
      color={muiColors.black}
      onClick={() => window.history.back()}
      startIcon={<ArrowBackIosNewIcon />}
      sx={sx}
    />
  );
}
