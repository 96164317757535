import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import ButtonView from "../../atoms/ButtonView/ButtonView";
import LabelView from "../../atoms/LabelView/LabelView";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useOutletContext, useNavigate } from "react-router-dom";
import {
  updateEvent,
  selectEventById,
  deleteEvent,
  cancelEvent,
} from "../../../features/events/eventsSlice";
import DatePickerView from "../../atoms/DatePickerView/DatePickerView";
import {
  cityLocations,
  eventInputFields,
  NEW_EVENT_ONE,
} from "../../../utils/Constants";
import { muiColors, redColor } from "../../../utils/globalStyles";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { capitalize } from "../../../utils/ProgUtils";

export default function EditEventView(props) {
  const { isMobile } = useOutletContext();
  const navigate = useNavigate();
  const { id } = useParams();
  const event = useSelector((state) => selectEventById(state, id));
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [eventDetails, setEventDetails] = useState({ ...event });
  const [errorMessage, setErrorMessage] = useState({
    id: "",
    message: "",
  });

  // Create new eventDetails object that only includes editable info e.g. excluding id, createdAt, etc.
  const usedKeys = Object.keys(NEW_EVENT_ONE);
  const editableEventDetails = {};
  const optionalEventDetails = [
    "hosts",
    "coverCharge",
    "musicProvider",
    "eventImageUrl",
    "prefillEventId",
    "isRecurring",
    "isMajor",
  ];

  usedKeys.forEach((key) => {
    // only gets eventImageUrl after image is uploaded to Cloudinary. Requiring this field to submit is a catch-22, so it is excluded from the check
    if (!optionalEventDetails.includes(key))
      editableEventDetails[key] = eventDetails[key];
  });

  // if any field is falsey, form is incomplete
  const isFormComplete = !Object.values(editableEventDetails).some(
    (detail) => !detail
  );

  const onImageChange = (e) =>
    setEventDetails({ ...eventDetails, eventImage: e.target.files[0] });

  const checkEndtime = () => {
    if (eventDetails.startTime && eventDetails.endTime) {
      if (eventDetails.startTime > eventDetails.endTime) {
        return false;
      }
    }
    return true;
  };

  const onSubmit = async () => {
    if (!checkEndtime()) {
      alert("End time must be after start time");
      return;
    }

    try {
      // Trying to pass updated events to EventFeedView directly through location.state
      // const resp = await dispatch(updateEvent(eventDetails));
      // const updatedEvents = resp.payload.events;
      // navigate("/", { state: { updatedEvents: updatedEvents } });
      setIsLoading(true);
      const resp = await dispatch(updateEvent(eventDetails)).unwrap();
      if (resp.result === "success") {
        alert("Event updated successfully!");
        navigate(-1);
      }
    } catch (err) {
      console.error("ON SUBMIT ERROR", err);
      setIsLoading(false);
      setErrorMessage({
        id: err.errorId,
        message: err.message,
      });
    }
  };

  const onDeleteClick = async (id) => {
    try {
      await dispatch(deleteEvent(id));
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const onCancelClick = async (id) => {
    try {
      await dispatch(cancelEvent(id));
      alert("Event cancelled for this week only.");
      navigate("/");
    } catch (e) {
      console.error(e);
    }
  };

  const dynamicInput = {
    ...styles.input,
    width: isMobile ? "90%" : 400,
  };

  const eventInput = ({ title, id }) => {
    switch (id) {
      case "startTime":
      case "endTime":
        return (
          <DatePickerView
            key={id}
            label={title}
            value={eventDetails[id]}
            onChange={(value) =>
              setEventDetails({ ...eventDetails, [id]: value?.$d })
            }
            sx={dynamicInput}
          />
        );
      case "city":
        // Not sure why the label isn't working properly. This is weird and dumb.
        return (
          <FormControl key={id}>
            <InputLabel style={{ marginTop: 8, marginLeft: 6 }}>
              City *
            </InputLabel>
            <Select
              label={"city"}
              variant="outlined"
              sx={dynamicInput}
              value={eventDetails[id]}
              onChange={(e) =>
                setEventDetails({ ...eventDetails, [id]: e.target.value })
              }
            >
              {cityLocations.map((location) => (
                <MenuItem key={location} value={location}>
                  {location}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case "description":
        return (
          <div key={id}>
            <TextField
              label={title}
              variant="outlined"
              required
              sx={{ ...dynamicInput, width: isMobile ? "90%" : 600 }}
              value={eventDetails[id]}
              onChange={(e) =>
                setEventDetails({ ...eventDetails, [id]: e.target.value })
              }
              multiline
              rows={12}
            />
          </div>
        );
      case "eventImage":
        return (
          <div key={id}>
            <input
              type="file"
              accept="image/png, image/jpeg, image/webp"
              multiple={false}
              onChange={(e) => onImageChange(e)}
            />
            {!eventDetails.eventImage && (
              <LabelView text="JPEG/PNG/WEBP, max 2MB" />
            )}
            {eventDetails.eventImageUrl && (
              <img
                alt={"Event Poster"}
                src={eventDetails.eventImageUrl}
                height={120}
                style={{ objectfit: "contain", color: "rgba(0,0,0,0)" }}
              />
            )}
          </div>
        );
      case "isRecurring":
        return (
          <FormControlLabel
            key={id}
            label={"Is this a weekly recurring event?"}
            disabled={eventDetails.isMajor}
            style={{ marginLeft: 0 }}
            control={
              <Checkbox
                checked={eventDetails.isRecurring}
                onChange={(e) => {
                  if (!eventDetails.isMajor) {
                    setEventDetails({
                      ...eventDetails,
                      isRecurring: e.target.checked,
                    });
                  }
                }}
              />
            }
          />
        );
      case "isMajor":
        return (
          <FormControlLabel
            key={id}
            label="This is a special, multi-day event, like a conference"
            style={{ marginLeft: 0 }}
            control={
              <Checkbox
                checked={eventDetails.isMajor}
                disabled={eventDetails.isRecurring}
                onChange={(e) => {
                  if (!eventDetails.isRecurring) {
                    setEventDetails({
                      ...eventDetails,
                      isMajor: e.target.checked,
                    });
                  }
                }}
              />
            }
          />
        );
      default:
        return (
          <div key={id}>
            <TextField
              label={title}
              variant="outlined"
              required={!["coverCharge", "musicProvider", "hosts"].includes(id)}
              sx={dynamicInput}
              value={eventDetails[id] || ""}
              onChange={(e) =>
                setEventDetails({ ...eventDetails, [id]: e.target.value })
              }
            />
          </div>
        );
    }
  };

  const firstButtons = (
    <>
      <ButtonView
        text="Update Event"
        color={muiColors.success}
        variant="contained"
        onClick={onSubmit}
        async // to="/" // I'll have to update this to make it async
        size={isMobile ? "small" : undefined}
        disabled={!isFormComplete || isLoading}
        sx={{ mr: 2 }}
      />
      <ButtonView
        text="Back"
        color={muiColors.black}
        variant="contained"
        onClick={() => navigate(-1)}
        size={isMobile ? "small" : undefined}
        disabled={isLoading}
        sx={{ mr: 2 }}
      />
    </>
  );

  const secondButtons = (
    <>
      <ButtonView
        text="Cancel this week"
        onClick={() => onCancelClick(id)}
        size={isMobile ? "small" : undefined}
        variant="contained"
        color={muiColors.error}
        sx={{ mr: 2 }}
      />
      <ButtonView
        variant="contained"
        color={muiColors.error}
        text="Delete Event"
        onClick={() => onDeleteClick(id)}
        size={isMobile ? "small" : undefined}
        disabled={isLoading}
      />
    </>
  );

  const dynamicInputsContainer = {
    ...styles.inputsContainer,
    // setting a percentage is working in settingsView
    height: isMobile ? undefined : 660, // harcoding this height is annoying, but so are big input fields
    marginBottom: isMobile ? 12 : undefined,
  };

  const usedEventInputFields = eventDetails.isMajor
    ? eventInputFields.filter((field) => field.isMajor)
    : eventInputFields;

  return (
    <div style={styles.container}>
      <h3>Update Event</h3>
      <div style={dynamicInputsContainer}>
        {usedEventInputFields.map((field) => eventInput(field))}
      </div>
      <LabelView
        key={id}
        text={`Status: ${capitalize(event.status)}`}
        style={{ marginBottom: 8 }}
      />
      <div style={{ display: "flex" }}>
        {firstButtons}
        {!isMobile && secondButtons}
      </div>
      {isMobile && (
        <div style={{ display: "flex", marginTop: 12 }}>{secondButtons}</div>
      )}

      {!!errorMessage.id && (
        <LabelView text={errorMessage.message} style={{ color: redColor }} />
      )}
      {isLoading && <div>Sending data, please wait...</div>}
    </div>
  );
}

const styles = {
  container: {
    padding: 16,
  },
  inputsContainer: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
  },
  input: {
    m: 1,
  },
};
