import React from "react";
import { Outlet, Navigate, useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectIsAdmin } from "./UserPermissions";

// This wraps all protected routes. Must always return a <Route> component.
// <Outlet> serves as a pass through for the protected child route

const ProtectedAdminRoutes = () => {
  const { isMobile } = useOutletContext();
  const isLoggedIn = useSelector((state) => state.users.isLoggedIn);
  const isAdmin = useSelector(selectIsAdmin);

  return isLoggedIn && isAdmin ? (
    <Outlet context={{ isMobile }} />
  ) : (
    <Navigate to="/" />
  );
};

export default ProtectedAdminRoutes;

// Following this video: https://www.youtube.com/watch?v=2k8NleFjG7I
// Also useful https://stackoverflow.com/questions/62384395/protected-route-with-react-router-v6
