import React from "react";
import { useNavigate } from "react-router-dom";
import LabelView from "../../atoms/LabelView/LabelView";
import placeholderImage from "../../../resources/images/event_placeholder_612x612.jpg";
import dayjs from "dayjs";
import {
  grayColor1,
  grayColor2,
  whiteColor,
  paletteRed,
} from "../../../utils/globalStyles";

export default function SingleEventMainView(props) {
  const { event, isFeed } = props; // should pass id and fetch from id?
  const {
    id,
    name,
    venue,
    startTime,
    endTime,
    eventImageUrl,
    isMajor,
    status,
  } = event;
  const navigate = useNavigate();
  const startDate = dayjs(startTime);
  const endDate = dayjs(endTime);
  const isMultiDayEvent =
    isMajor &&
    dayjs(startTime).startOf("day").isBefore(dayjs(endTime).startOf("day"));

  const singleDate = (
    <div style={styles.dateContainer}>
      <LabelView text={startDate.format("MMMM")} isBold />
      <LabelView text={startDate.date()} isBold />
    </div>
  );

  const dateRange = (
    <div style={{ ...styles.dateContainer, height: 64 }}>
      <LabelView
        text={`${startDate.format("MMM DD")} - ${endDate.format("MMM DD")}`}
        isBold
      />
    </div>
  );

  const dynamicContainerStyle = {
    ...styles.container,
    boxShadow: isFeed ? "0px 4px 4px 4px rgba(0,0,0,0.2)" : undefined,
    cursor: !!eventImageUrl ? "pointer" : undefined,
    border: event.isMajor ? `3px solid ${paletteRed}` : "",
  };

  const dynamicImageContainer = {
    ...styles.imageContainer,
    backgroundColor: grayColor2,
  };

  return (
    <div
      style={dynamicContainerStyle}
      onClick={isFeed ? () => navigate(`/events/${id}`) : undefined}
    >
      <div style={dynamicImageContainer}>
        {!eventImageUrl && isFeed && (
          <LabelView
            text={`${name} @ ${venue}`}
            type="smalltitle"
            style={styles.placeholderEventTitle}
          />
        )}
        <img
          src={eventImageUrl || placeholderImage}
          alt="Event Poster"
          style={{
            ...styles.image,
            filter: !!eventImageUrl ? undefined : "opacity(0.1)",
          }}
        />
      </div>

      <div style={styles.dateInfoContainer}>
        {isMultiDayEvent ? dateRange : singleDate}
        {!isMultiDayEvent && (
          <div style={styles.timeContainer}>
            <LabelView text={`Start: ${startDate.format("HH:mm")}`} isBold />
            <LabelView text={`End: ${endDate.format("HH:mm")}`} isBold />
          </div>
        )}
      </div>
    </div>
  );
}

const styles = {
  container: {
    maxWidth: 320,
    minWidth: 320,
    padding: 16,
    margin: 8,
    borderRadius: 8,
    backgroundColor: grayColor1,
    marginBottom: "auto",
  },
  imageContainer: {
    borderRadius: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  image: {
    width: "100%",
    borderRadius: 8,
    height: 420,
    objectFit: "contain",
  },
  dateInfoContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    marginTop: 12,
  },
  dateContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: whiteColor,
    borderRadius: 4,
    padding: 8,
    justifyContent: "center",
    alignItems: "center",
  },
  timeContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: whiteColor,
    borderRadius: 4,
    padding: 8,
    justifyContent: "start",
  },
  placeholderEventTitle: {
    position: "absolute",
    top: "8%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    width: "90%",
    textAlign: "center",
  },
};
