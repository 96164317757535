import React, { useState, useEffect } from "react";
import { useParams, useOutletContext } from "react-router-dom";
import LabelView from "../../atoms/LabelView/LabelView";
import BackArrowButton from "../../molecules/BackArrowButton";
import { grayColor1 } from "../../../utils/globalStyles";
import { useSelector, useDispatch } from "react-redux";
import { getHosts, selectHostById } from "../../../features/hosts/hostsSlice";
import waImage from "../../../resources/images/wa-logo-48.png";
import instaImage from "../../../resources/images/instagram-logo-48.png";
import telegramImage from "../../../resources/images/telegram-logo-48.png";
import facebookImage from "../../../resources/images/facebook-logo-32.png";
import placeholderImage from "../../../resources/images/event_placeholder_612x612.jpg";
import LinkView from "../../atoms/LinkView/LinkView";

export default function HostProfilePageView(props) {
  const { isMobile } = useOutletContext();
  const dispatch = useDispatch();
  const params = useParams();
  const userId = Number(params.id);
  const [hostDetails, setHostDetails] = useState({});

  const currentHost = useSelector(
    (state) => selectHostById(state, userId) || {}
  );

  useEffect(() => {
    if (currentHost.id) {
      setHostDetails(currentHost);
    } else {
      // in case new user comes directly to host profile
      dispatch(getHosts()).then((resp) => {
        // could reach into redux but the whole useSelector thing is making me mad.
        setHostDetails(resp.payload.users.find((user) => user.id === userId));
      });
      // .catch((e) => console.log(e));
    }
  }, [userId, dispatch, currentHost]);

  // const hostDetails = ({
  //   firstName,
  //   lastName,
  //   email,
  //   password,
  //   confirmPassword,
  //   isHostRequested,
  //   isHostConfirmed,
  //   role,
  //   whatsappNumber,
  //   // bio,
  // } = hostDetails);

  const {
    name,
    bio,
    whatsappNumber = "",
    instagramUniqueId,
    telegramUrl,
    facebookUrl,
    websiteUrl,
    userImageUrl,
  } = hostDetails;

  const contactDisplay = () => {
    const formattedWhatsapp = whatsappNumber.replace(/\D/g, "");
    return (
      <>
        {whatsappNumber && (
          <div style={styles.contactContainer}>
            <img
              src={waImage}
              alt="whatsapp_icon"
              style={{ marginRight: 4, height: 32 }}
            />
            <LinkView
              text="Send Message"
              //https://wa.me/552196312XXXX?text=Send20%a20%quote
              href={`https://api.whatsapp.com/send?phone=${formattedWhatsapp}`}
              target="_blank"
            />
          </div>
        )}

        {instagramUniqueId && (
          <div style={styles.contactContainer}>
            <img
              src={instaImage}
              alt="instagram_icon"
              style={{ marginRight: 6, height: 30 }}
            />
            <LinkView
              text="Instagram"
              href={`https://instagram.com/${instagramUniqueId}`}
              target="_blank"
            />
          </div>
        )}

        {telegramUrl && (
          <div style={styles.contactContainer}>
            <img
              src={telegramImage}
              alt="telegram_icon"
              style={{ marginRight: 6, height: 28 }}
            />
            <LinkView text="Send Message" href={telegramUrl} target="_blank" />
          </div>
        )}

        {facebookUrl && (
          <div style={styles.contactContainer}>
            <img
              src={facebookImage}
              alt="facebook_icon"
              style={{ marginRight: 6, height: 28 }}
            />
            <LinkView text="View page" href={facebookUrl} target="_blank" />
          </div>
        )}

        {websiteUrl && (
          <div style={styles.contactContainer}>
            {/* <img src={waImage} style={{ marginRight: 4, height: 36 }} /> */}
            <LinkView
              text="Check out their website!"
              href={websiteUrl}
              target="_blank"
            />
          </div>
        )}
      </>
    );
  };

  const webHostProfile = (
    <div style={styles.container}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <BackArrowButton />
        <LabelView text={name} type="smalltitle" />
      </div>
      <div style={{ display: "flex" }}>
        <div>
          <img
            src={userImageUrl || placeholderImage}
            alt="Host Profile"
            objectfit="contain"
            style={{
              borderRadius: 8,
              maxHeight: 500,
              width: 340, // maybe add a container like in events so loading doesn't get fucked up
              margin: "8px 0px",
              boxShadow: "0px 4px 8px 4px rgba(0,0,0,0.3)",
              // filter: !!event.eventImageUrl ? undefined : "opacity(0.1)",
            }}
          />
        </div>

        <div style={styles.longDescriptionContainer}>
          <h3>{name}</h3>

          <h3 style={{ marginTop: 12 }}>Bio</h3>
          <LabelView text={bio} style={{ whiteSpace: "pre-wrap" }} />
          <h3 style={{ marginTop: 12 }}>Contact</h3>
          {contactDisplay()}
          {/* <h3 style={{ marginTop: 12 }}>Upcoming Events</h3>
          <LabelView text="This will be a really cool tool someday" /> */}
        </div>
      </div>
    </div>
  );

  const mobileHostProfile = (
    <div style={styles.container}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <BackArrowButton sx={{ mb: 1 }} />
        <h3>{name}</h3>
        <div style={{ width: 64 }} /> {/* this is to center the title */}
      </div>
      <div style={styles.mobileLongDescription}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={userImageUrl || placeholderImage}
            alt="Host Profile"
            objectfit="contain"
            style={{
              borderRadius: 8,
              maxHeight: 300,
              filter: !!userImageUrl ? undefined : "opacity(0.1)",
            }}
            // onClick={onImageClick()}
          />
        </div>

        <div style={styles.mobileLongDescriptionContainer}>
          <h3 style={{ marginTop: 12 }}>Bio</h3>
          <LabelView text={bio} style={{ whiteSpace: "pre-wrap" }} />
          <h3 style={{ marginTop: 12 }}>Contact</h3>
          {contactDisplay()}
          {/* <h3 style={{ marginTop: 12 }}>Upcoming Events</h3> */}
          {/* <LabelView text="This will be a really cool tool someday" /> */}
        </div>
      </div>
    </div>
  );

  return (
    <div style={styles.container}>
      {isMobile ? mobileHostProfile : webHostProfile}
    </div>
  );
}

const styles = {
  container: {
    padding: 16,
  },
  longDescriptionContainer: {
    display: "flex",
    flexDirection: "column",
    width: "40%",
    minWidth: 450,
    padding: 16,
    margin: 8,
    borderRadius: 8,
    backgroundColor: grayColor1,
  },
  mobileLongDescriptionContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: 16,
    padding: 16,
    borderRadius: 8,
    backgroundColor: grayColor1,
  },
  contactContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 12,
  },
};
