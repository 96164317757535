import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

const ButtonView = (props) => {
  const {
    id,
    variant,
    text,
    onClick,
    size,
    sx = {},
    color,
    to,
    disabled,
    startIcon,
  } = props;

  return (
    <Button
      id={id}
      variant={variant}
      onClick={onClick}
      sx={sx}
      size={size}
      color={color}
      to={to}
      component={to ? Link : undefined}
      disabled={disabled}
      startIcon={startIcon}
    >
      {text}
    </Button>
  );
};

export default ButtonView;
