import { Component } from "react";

class ErrorBoundary extends Component {
  componentDidCatch(error, info) {
    console.error("Error caught by error boundary:", error);
    console.error("Error info:", info);
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
