import React from "react";
import LinkInternalView from "../../atoms/LinkInternalView/LinkInternalView";
import { blackColor } from "../../../utils/globalStyles";

export default function FooterView(props) {
  const { isMobile } = props;

  const linkText = {
    terms: isMobile ? "T's & C's" : "Terms & Conditions ",
    privacy: isMobile ? "Privacy" : "Privacy Policy",
    contact: isMobile ? "Contact" : "Contact Us",
    about: isMobile ? "About" : "About Us",
  };

  const dynamicLinkContainer = {
    ...styles.linkContainer,
    padding: isMobile ? "0px 8px" : "0px 16px",
  };

  return (
    <div style={styles.footer}>
      <div style={dynamicLinkContainer}>
        <div style={styles.halfLinkContainer}>
          <LinkInternalView text={linkText.about} to="/about" />
          {/* <LinkInternalView text={linkText.contact} to="/contact" /> */}
        </div>
        <div style={styles.halfLinkContainer}>
          <LinkInternalView text={linkText.terms} to="/terms" />
          <LinkInternalView text={linkText.privacy} to="/privacy" />
        </div>
      </div>
    </div>
  );
}

const styles = {
  footer: {
    display: "flex",
    alignItems: "center",
    background: blackColor,
    minHeight: 40,
  },
  linkContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  halfLinkContainer: {
    display: "flex",
  },
};
