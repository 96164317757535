import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { getContacts, createContact } from "../contacts";
import HeaderView from "../components/organisms/HeaderView/HeaderView.js";
import FooterView from "../components/organisms/FooterView/FooterView";
import MobileNavView from "../components/organisms/MobileNavView/MobileNavView.js";

export async function loader() {
  const contacts = await getContacts();
  return { contacts };
}

export async function action() {
  const contact = await createContact();
  return { contact };
}

export default function Root() {
  // const { contacts } = useLoaderData();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const updateWindowWidth = () => {
      const currentWidth = window.innerWidth;
      const shouldUpdate =
        (currentWidth <= 600 && !isMobile) || (currentWidth >= 600 && isMobile);
      if (shouldUpdate) setIsMobile(!isMobile);
    };

    window.addEventListener("resize", updateWindowWidth);

    return () => window.removeEventListener("resize", updateWindowWidth);
  });

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <HeaderView isMobile={isMobile} />
      <div
        id="detail"
        style={{
          // This styling is applied to most in-app views. Could add padding
          flexGrow: 1,
          flexDirection: "column",
          display: "flex",
        }}
      >
        {/* mb accounts for <MobileNavView */}
        <div style={{ marginBottom: isMobile ? 48 : 0 }}>
          <Outlet context={{ isMobile }} />
        </div>
        {isMobile && <MobileNavView />}
      </div>
      {!isMobile && <FooterView isMobile={isMobile} />}
      {/*       
      <div id="sidebar">
        <h1>React Router Contacts</h1>
        <div>
          <form id="search-form" role="search">
            <input
              id="q"
              aria-label="Search contacts"
              placeholder="Search"
              type="search"
              name="q"
            />
            <div
              id="search-spinner"
              aria-hidden
              hidden={true}
            />
            <div
              className="sr-only"
              aria-live="polite"
            ></div>
          </form>
          <Form method="post">
            <button type="submit">New</button>
          </Form>
        </div>
        <nav>
        {contacts.length ? (
          <ul>
            {contacts.map((contact) => (
              <li key={contact.id}>
                <Link to={`contacts/${contact.id}`}>
                  {contact.first || contact.last ? (
                    <>
                      {contact.first} {contact.last}
                    </>
                  ) : (
                    <i>No Name</i>
                  )}{" "}
                  {contact.favorite && <span>★</span>}
                </Link>
              </li>
            ))}
          </ul>
        ) : (
          <p>
            <i>No contacts</i>
          </p>
        )}
        </nav>
      </div>
      <div id="detail">
        <Outlet />
      </div> */}
    </div>
  );
}
