import { createSlice } from "@reduxjs/toolkit";

// THUNKS

// REDUCERS

const initialState = {
  locations: [],
  styles: [],
  isFree: false,
  showMyEvents: false,
  futureDays: 0,
};

export const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    // doSomethingEvent: () => {},
    isFreeFilterChanged(state) {
      state.isFree = !state.isFree;
    },
    isShowMyEventsFilterChanged(state) {
      state.showMyEvents = !state.showMyEvents;
    },
    dateFilterChanged: {
      reducer(state, action) {
        const { futureDays } = action.payload;
        state.futureDays = futureDays;
      },
      prepare(futureDays) {
        return {
          payload: { futureDays },
        };
      },
    },

    locationFilterChanged: {
      reducer(state, action) {
        let { location, changeType } = action.payload;
        const { locations } = state;
        switch (changeType) {
          case "added": {
            if (!locations.includes(location)) {
              locations.push(location);
            }
            break;
          }
          case "removed": {
            state.locations = locations.filter(
              (existingLocation) => existingLocation !== location
            );
            break;
          }

          default:
            return;
        }
      },
      prepare(location, changeType) {
        return {
          payload: { location, changeType },
        };
      },
    },
  },
  extraReducers: (builder) => {
    // builder
    //   .addCase(getEvents.pending, (state, action) => {
    //     state.status = "loading";
    //   })
  },
});

// ACTION CREATORS

// Action creators are generated for each case reducer function
export const {
  statusFilterChanged,
  locationFilterChanged,
  dateFilterChanged,
  isFreeFilterChanged,
  isShowMyEventsFilterChanged,
} = filtersSlice.actions;

export default filtersSlice.reducer;

// SELECTORS
