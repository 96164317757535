import React, { useEffect } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";
import LabelView from "../../atoms/LabelView/LabelView";
import placeholderImage from "../../../resources/images/event_placeholder_612x612.jpg";
import {
  getHosts,
  selectHostById,
  // selectHostIds,
} from "../../../features/hosts/hostsSlice";
import { useDispatch, useSelector } from "react-redux";

export default function HostsOverviewView(props) {
  const { isMobile } = useOutletContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.users.currentUser);

  useEffect(() => {
    dispatch(getHosts());
  }, [dispatch]);

  // there's some fuckery going on here with useSelector and calling an already-defined selector, no?
  // const hostIds = useSelector((state) => selectHostIds(state)); // this seems complicated
  const hostIds = useSelector((state) => state.hosts.ids); // this is no less abstracted and more explicit
  // const hostIds = selectHostIds(); // this just breaks, why do i have to pass state from the component?

  const HostIcon = (props) => {
    const { hostId } = props;
    const host = useSelector((state) => selectHostById(state, hostId));

    // hide admins from host overview
    // if (host.role === "admin" && currentUser.role !== "admin") return null;

    const size = isMobile ? 90 : 160;

    const dynamicHostContainer = {
      ...styles.hostContainer,
      margin: isMobile ? 12 : 24,
    };

    const dynamicHostImage = {
      ...styles.hostImage,
      width: size,
      height: size,
      objectFit: "cover",
    };

    return (
      <div style={dynamicHostContainer}>
        <img
          src={host.userImageUrl || placeholderImage}
          alt={host.name}
          style={dynamicHostImage}
          onClick={() => navigate(`/hostsOverview/${host.id}`)}
        />
        <LabelView text={host.name} style={{ marginTop: 8 }} isBold />
      </div>
    );
  };

  const dynamicHostsContainer = {
    ...styles.hostsContainer,
    width: isMobile ? undefined : "70%",
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Meet the Community</h1>
      <div style={dynamicHostsContainer}>
        {hostIds.map((hostId) => (
          <HostIcon hostId={hostId} key={hostId} />
        ))}
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 16,
  },
  heading: {
    margin: "16px 0px",
  },
  hostsContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    cursor: "pointer",
  },
  hostContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  hostImage: {
    borderRadius: "50%",
  },
};
