import React, { useState } from "react";
import { useParams, useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectEventById } from "../../../features/events/eventsSlice";
import LabelView from "../../atoms/LabelView/LabelView";
import LinkView from "../../atoms/LinkView/LinkView";
import SingleEventMainView from "../../organisms/SingleEventMainView/SingleEventMainView";
import placeholderImage from "../../../resources/images/event_placeholder_612x612.jpg";
import {
  grayColor1,
  lightBlueColor,
  whiteColor,
} from "../../../utils/globalStyles";
import { formatPrice } from "../../../utils/ProgUtils";
import BackArrowButton from "../../molecules/BackArrowButton";
import dayjs from "dayjs";

export default function SingleEventView(props) {
  const { id } = useParams();
  const { isMobile } = useOutletContext();
  const [isEventPosterModalOpen, setIsEventPosterModalOpen] = useState(false);

  const event = useSelector((state) => selectEventById(state, id)) || {};
  const {
    name,
    hosts,
    musicProvider,
    coverCharge,
    city,
    venue,
    locationLink,
    startTime,
    endTime,
    isMajor,
    description,
    eventImageUrl,
  } = event;

  // see if I can refactor some of the mobile into singleMainEventView. Perhaps I can add this new view to the top and just tack the extra on the bottom?
  const startDate = dayjs(startTime);
  const endDate = dayjs(endTime);

  const isMultiDayEvent =
    isMajor && startDate.startOf("day").isBefore(endDate.startOf("day"));

  const singleDate = (
    <div style={styles.dateContainer}>
      <LabelView text={startDate.format("MMMM")} isBold />
      <LabelView text={startDate.date()} isBold />
    </div>
  );

  const dateRange = (
    <div style={{ ...styles.dateContainer, height: 64 }}>
      <LabelView
        text={`${startDate.format("MMM DD")} - ${endDate.format("MMM DD")}`}
        isBold
      />
    </div>
  );

  const eventModalPoster = (
    <div className="modal" onClick={() => setIsEventPosterModalOpen(false)}>
      <img
        alt="Event Poster"
        src={eventImageUrl}
        style={{ height: isMobile ? "60vh" : "80vh" }}
      />
    </div>
  );

  const onImageClick = () =>
    !!eventImageUrl ? () => setIsEventPosterModalOpen(true) : undefined;

  const webSingleEvent = (
    <div style={styles.container}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <BackArrowButton />
        <LabelView text={name} type="smalltitle" />
      </div>
      <div style={{ display: "flex" }}>
        <div onClick={onImageClick()}>
          <SingleEventMainView event={event} />
        </div>

        <div style={styles.longDescriptionContainer}>
          <h3>{name}</h3>
          <LabelView text={description} style={{ whiteSpace: "pre-wrap" }} />

          {(!!hosts || !!musicProvider) && (
            <h3 style={{ marginTop: 12 }}>Hosts & Music</h3>
          )}
          {!!hosts && (
            <div style={{ display: "flex" }}>
              <LabelView text="Hosted by:" style={{ marginRight: 2 }} />
              <LabelView text={hosts} />
            </div>
          )}
          {!!musicProvider && (
            <div style={{ display: "flex" }}>
              <LabelView text="Music by:" style={{ marginRight: 2 }} />
              <LabelView text={musicProvider} />
            </div>
          )}

          <div style={{ marginTop: "auto" }}>
            <h3 style={{ marginTop: 12 }}>Price</h3>
            <LabelView text={formatPrice(coverCharge)} />

            <h3 style={{ marginTop: 12 }}>Location</h3>
            <div style={{ display: "flex" }}>
              <LabelView text={`${city} @`} style={{ marginRight: 2 }} />
              <LinkView text={venue} href={locationLink} target="_blank" />
            </div>

            {/* With the key, the iframe below is working */}
            {/* <iframe
              width="400"
              height="300"
              frameBorder="0"
              // style="border:0"
              referrerPolicy="no-referrer-when-downgrade"
              // src={`https://www.google.com/maps/embed/v1/place?key=${ENV["MAPS_API_KEY"]}&q=warung+italia+bali`}
              src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCmFu05PfWap67MRkYWGc9cDAnmIagXk98&q=warung+italia+bali`}
              allowFullScreen
            ></iframe> */}

            {/* The iframe below is working with the full google url */}
            {/* <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15776.875567122428!2d115.1611142!3d-8.6707221!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd247217dd1682f%3A0xcd6de53a8ad0d8de!2sWarung%20Italia%20Bali!5e0!3m2!1sen!2sid!4v1706422050529!5m2!1sen!2sid"
              width="400"
              height="300"
              // style="border:0;"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe> */}
          </div>
        </div>
        {/* <DummyAnnoucement /> */}
      </div>
    </div>
  );

  const mobileSingleEvent = (
    <div style={styles.container}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <BackArrowButton sx={{ mb: 1 }} />
        <h3>{name}</h3>
        <div style={{ width: 64 }} /> {/* this is to center the title */}
      </div>
      <div style={styles.mobileLongDescription}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={eventImageUrl || placeholderImage}
            alt="Event Poster"
            style={{
              borderRadius: 8,
              maxHeight: 300,
              objectFit: "contain",
              width: "100%",
              filter: !!eventImageUrl ? undefined : "opacity(0.1)",
            }}
            onClick={onImageClick()}
          />
        </div>

        {/* This is copied directly from above */}
        <div style={styles.dateInfoContainer}>
          {isMultiDayEvent ? dateRange : singleDate}
          {!isMultiDayEvent && (
            <div style={styles.timeContainer}>
              <LabelView text={`Start: ${startDate.format("HH:mm")}`} isBold />
              <LabelView text={`End: ${endDate.format("HH:mm")}`} isBold />
            </div>
          )}
        </div>

        <div style={styles.mobileLongDescriptionContainer}>
          <LabelView text={description} style={{ whiteSpace: "pre-wrap" }} />
          <h3 style={{ marginTop: 12 }}>Location</h3>
          <div style={{ display: "flex" }}>
            <LabelView text={`${city} @`} style={{ marginRight: 2 }} />
            <LinkView text={venue} href={locationLink} target="_blank" />
          </div>
          {(!!hosts || !!musicProvider) && (
            <h3 style={{ marginTop: 12 }}>Hosts & Music</h3>
          )}
          {!!hosts && (
            <div style={{ display: "flex" }}>
              <LabelView text="Hosted by:" style={{ marginRight: 2 }} />
              <LabelView text={hosts} />
            </div>
          )}
          {!!musicProvider && (
            <div style={{ display: "flex" }}>
              <LabelView text="Music by:" style={{ marginRight: 2 }} />
              <LabelView text={musicProvider} />
            </div>
          )}
          <div>
            <h3 style={{ marginTop: 12 }}>Price</h3>
            <LabelView text={formatPrice(coverCharge)} />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {isMobile ? mobileSingleEvent : webSingleEvent}
      {isEventPosterModalOpen && eventModalPoster}
    </>
  );
}

const styles = {
  container: {
    padding: 16,
  },
  leftImageContainer: {
    maxWidth: "30%",
    minWidth: 320,
    padding: 16,
    margin: 8,
    borderRadius: 8,
    backgroundColor: grayColor1,
    marginBottom: "auto",
  },
  longDescriptionContainer: {
    display: "flex",
    flexDirection: "column",
    width: "40%",
    minWidth: 450,
    padding: 16,
    margin: 8,
    borderRadius: 8,
    backgroundColor: grayColor1,
  },
  mobileLongDescriptionContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: 16,
    borderRadius: 8,
    backgroundColor: grayColor1,
  },
  dateInfoContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    marginTop: 12,
  },
  dateContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: whiteColor,
    borderRadius: 4,
    padding: 8,
    justifyContent: "center",
    alignItems: "center",
  },
  timeContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: whiteColor,
    borderRadius: 4,
    padding: 8,
    justifyContent: "start",
  },
  eventPlaceholder: {
    height: "85%",
    width: "100%",
    borderRadius: 8,
    background: "goldenrod",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  dummyAnnoucement: {
    height: 300,
    width: 400,
    margin: 8,
    background: lightBlueColor,
  },

  // mobile styling
  mobileLongDescription: {
    padding: 16,
    borderRadius: 8,
    backgroundColor: grayColor1,
  },
};
