import React from "react";
import LabelView from "../../atoms/LabelView/LabelView";
import { selectEventsOfUser } from "../../../features/events/eventsSlice";
import { useOutletContext } from "react-router-dom";
import BackArrowButton from "../../molecules/BackArrowButton";
import EventListView from "../../organisms/EventListView/EventListView";
import { useSelector } from "react-redux";

export default function MyEventsView(props) {
  const { isMobile } = useOutletContext();
  const eventsOfUser = useSelector(selectEventsOfUser);

  return (
    <div style={styles.container}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <BackArrowButton sx={{ mb: 1, mr: 1 }} />
        <h3>Host Dashboard</h3>
        <div style={{ width: 64 }} />
      </div>
      <LabelView
        text="Your Events"
        isBold
        style={{ textAlign: isMobile ? "center" : undefined, size: 24 }}
      />
      <EventListView eventsOfUser={eventsOfUser} />
    </div>
  );
}

const styles = {
  container: {
    padding: 16,
  },
};
