import FormData from "form-data";
import dayjs from "dayjs";

export const capitalize = (s) => s[0].toUpperCase() + s.slice(1);

export function hexToRgb(hex, opacity = "0.15") {
  let h = hex.replace("#", "");
  h = h.match(new RegExp("(.{" + h.length / 3 + "})", "g"));

  for (let i = 0; i < h.length; i++)
    h[i] = parseInt(h[i].length === 1 ? h[i] + h[i] : h[i], 16);

  if (typeof opacity != "undefined") h.push(opacity);

  return "rgba(" + h.join(",") + ")";
}

export const formatPrice = (price) => {
  const costInt = parseInt(price); // removes decimals
  const formattedCost = price > 0 ? `${costInt.toString()}K` : "Free";
  return formattedCost;
};

// export function createParams(loginObject = {}, paramObject = {}) {
//   let paramObj = paramObject;
//   if (loginObject.email) paramObj['email'] = loginObject.email;
//   return Object.entries(paramObj)
//     .map(([k, v]) => {
//       return v || v === false || v === 0 ? `${k}=${encodeURIComponent(v)}` : null;
//     })
//     .filter((n) => n)
//     .join('&');
// }

export const validatePassword = (password, confirmPassword, email) => {
  let isValid = false;

  if (!!password && password.length < 8) {
    return { isValid, errorMessage: "Password must be at least 8 characters" };
  }

  if (password === email) {
    return { isValid, errorMessage: "Password cannot be same as email" };
  }

  if (!!password && password.length > 72) {
    return { isValid, errorMessage: "Password is too long" };
  }

  if (password !== confirmPassword) {
    return { isValid, errorMessage: "Passwords must match" };
  }

  isValid = true;
  return { isValid };
};

export const validateEmail = (email) => {
  let isValid = false;

  let isValidEmail = String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

  if (!isValidEmail) {
    return { isValid, errorMessage: "Invalid email" };
  }

  isValid = true;
  return { isValid };
};

export const isToday = (time) => {
  const todaysDate = new Date().setHours(0, 0, 0, 0);
  const formatTime = (t) => new Date(t).setHours(0, 0, 0, 0);
  return todaysDate === formatTime(time);
};

export const addOneWeek = (startTime) =>
  dayjs(startTime).add(1, "week").format();

export const convertToFormData = (newEvent) => {
  const formData = new FormData();
  // Destructure newEvent for cleaner code
  const {
    name,
    city,
    locationLink,
    venue,
    hosts,
    coverCharge,
    styles,
    description,
    startTime,
    endTime,
    musicProvider,
    eventImage,
    prefillEventId,
    isRecurring,
    isMajor,
    status,
  } = newEvent;

  const strip = (str) => str.trim().replace(/\.$/, "");

  // Append form data dynamically using a loop with snake_case keys
  Object.entries({
    name: strip(name),
    city,
    location_link: locationLink,
    venue: strip(venue),
    hosts: strip(hosts),
    cover_charge: coverCharge,
    styles,
    description,
    start_time: startTime,
    end_time: endTime,
    music_provider: strip(musicProvider),
    event_image: eventImage,
    prefill_event_id: prefillEventId,
    is_recurring: isRecurring,
    is_major: isMajor,
    status, // needed as whole event updates each time. I can see why this is getting a little stupid.
  }).forEach(([key, value]) => {
    if (value !== undefined) {
      formData.append(`event[${key}]`, value);
    }
  });

  return formData;
};

export const convertToFormDataUser = (user) => {
  const formData = new FormData();
  // Destructure user for cleaner code
  const {
    name,
    email,
    password,
    confirmPassword,
    bio,
    whatsappNumber,
    userImage,
    instagramUniqueId,
    telegramUrl,
    facebookUrl,
    websiteUrl,
  } = user;

  // Append form data dynamically using a loop with snake_case keys
  Object.entries({
    name,
    email,
    password,
    confirm_password: confirmPassword,
    bio,
    whatsapp_number: whatsappNumber,
    user_image: userImage,
    instagram_unique_id: instagramUniqueId,
    telegram_url: telegramUrl,
    facebook_url: facebookUrl,
    website_url: websiteUrl,
  }).forEach(([key, value]) => {
    if (value !== undefined) {
      formData.append(`user[${key}]`, value);
    }
  });

  return formData;
};
