import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useOutletContext } from "react-router-dom";
import ButtonView from "../../atoms/ButtonView/ButtonView";
import EventItem from "../../../features/events/EventItem";
import { muiColors, blackColor } from "../../../utils/globalStyles";
import { selectIsAdmin } from "../../../utils/UserPermissions";
import { useSelector } from "react-redux";
import {
  editPlusOneWeek,
  makeToday,
} from "../../../features/events/eventsSlice";
import dayjs from "dayjs";

export default function EventListView(props) {
  const { eventsOfUser = [] } = props;
  const { isMobile } = useOutletContext();
  const [eventFilter, setEventFilter] = useState("today");
  const isAdmin = useSelector(selectIsAdmin);
  const dispatch = useDispatch();

  const onEditPlusOneWeek = (eventId) => dispatch(editPlusOneWeek(eventId));
  const onMakeToday = (eventId) => dispatch(makeToday(eventId));

  const todaysDate = new Date().setHours(0, 0, 0, 0);

  const formatTime = (time) => new Date(time).setHours(0, 0, 0, 0);

  const todaysEvents = eventsOfUser.filter(
    (event) => formatTime(event.startTime) === todaysDate
  );

  const pastEvents = eventsOfUser
    .filter((event) => formatTime(event.startTime) < todaysDate)
    .sort((a, b) => dayjs(b.startTime) - dayjs(a.startTime));

  const futureEvents = eventsOfUser
    .filter((event) => formatTime(event.startTime) > todaysDate)
    .sort((a, b) => dayjs(a.startTime) - dayjs(b.startTime));

  const eventsHash = {
    today: { title: "Today's Events", events: todaysEvents },
    future: { title: "Future Events", events: futureEvents },
    past: { title: "Past Events", events: pastEvents },
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: isMobile ? "center" : undefined,
        }}
      >
        <ButtonView
          text={`Today (${todaysEvents.length})`}
          variant="contained"
          color={eventFilter === "today" ? muiColors.primary : muiColors.black}
          onClick={() => setEventFilter("today")}
          sx={{ m: 1 }}
        />
        <ButtonView
          text={`Future (${futureEvents.length})`}
          variant="contained"
          color={eventFilter === "future" ? muiColors.primary : muiColors.black}
          onClick={() => setEventFilter("future")}
          sx={{ m: 1 }}
        />
        <ButtonView
          text={`Past (${pastEvents.length})`}
          variant="contained"
          color={eventFilter === "past" ? muiColors.primary : muiColors.black}
          onClick={() => setEventFilter("past")}
          sx={{ m: 1 }}
        />
      </div>
      <hr style={{ border: `2px solid ${blackColor}` }} />

      <div style={styles.eventsContainer}>
        {eventsHash[eventFilter].events.map((event) => (
          <div
            style={{ display: isMobile ? undefined : "flex" }}
            key={event.id}
          >
            <EventItem
              id={event.id}
              isMobile={isMobile}
              showEditButton
              showDate
            />
            {isAdmin && eventFilter === "past" && (
              <div>
                <ButtonView
                  text="+1 week (Admin)"
                  onClick={() => onEditPlusOneWeek(event.id)}
                  color={muiColors.primary}
                  variant="contained"
                  sx={{
                    height: 32,
                    width: 160,
                    ml: isMobile ? 0 : 1,
                    mt: 1,
                  }}
                />
                <ButtonView
                  text="Today (Admin)"
                  onClick={() => onMakeToday(event.id)}
                  color={muiColors.primary}
                  variant="contained"
                  sx={{ height: 32, width: 160, ml: 1, mt: 1 }}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

const styles = {
  eventsContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 600,
  },
};
