import { TERMS_TEXT, PRIVACY_TEXT, ABOUT_TEXT } from "../../../utils/Constants";

export default function TermsView(props) {
  const textBlockHash = {
    terms: TERMS_TEXT,
    privacy: PRIVACY_TEXT,
    about: ABOUT_TEXT,
  };

  const usedBlock = textBlockHash[props.textBlock];

  return (
    <div style={styles.container}>
      <h3>{usedBlock.title}</h3>
      <p>{usedBlock.content}</p>
    </div>
  );
}

const styles = {
  container: { whiteSpace: "pre-wrap", padding: "32px 32px 12px" },
};
