// Colors

export const blackColor = "#000";
export const whiteColor = "#FFF";
export const redColor = "#FF0000";
export const lightBlueColor = "#ADD8E6";
export const grayColor1 = "#F0F0F0";
export const grayColor2 = "#E0E0E0";
export const grayColor3 = "#999";

// Brand colors

export const paletteRed = "#811B20";

// MUI colors (for prop)
export const muiColors = {
  black: "black",
  primary: "paletteRed", // "primary"
  secondary: "secondary",
  error: "error",
  success: "paletteRed", // "success"
  green: "green",
  paletteRed: "paletteRed",
};
