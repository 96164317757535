import {
  // DesktopDateTimePicker,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import dayjs from "dayjs";

// Do i need to declare a const/let with this default export function?
export default function DatePickerView(props) {
  const { sx, onChange = () => {}, label, value = null } = props;
  const dayjsValue = dayjs(value);

  return (
    <MobileDateTimePicker
      sx={sx}
      onChange={onChange}
      label={label}
      ampm={false}
      value={dayjsValue}
      // defaultValue={dayjs(`${now.$y}-${now.$M + 1}-${now.$D}T19:00`)}
      // defaultValue={dayjs()}
    />

    // <MobileDateTimePicker
    //   sx={sx}
    //   onChange={onChange}
    //   label="Time & Date"
    //   defaultValue={dayjs(`${now.$y}-${now.$M + 1}-${now.$D}T19:00`)}
    // />
  );
}
