import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import LabelView from "../../atoms/LabelView/LabelView";
import AdminHostView from "../AdminHostsView/AdminHostsView";
import AdminEventView from "../AdminEventsView/AdminEventsView";
import AddHostView from "../AdminAddHostView/AdminAddHostView";
import ButtonView from "../../atoms/ButtonView/ButtonView";
import { blackColor, muiColors } from "../../../utils/globalStyles";
import { rescheduleEvents } from "../../../features/users/usersSlice";

export default function AdminView(props) {
  const { isMobile } = useOutletContext();
  const [selectedPanel, setSelectedPanel] = useState("event");

  const buttonDetails = [
    { id: "event", text: "Event Panel" },
    { id: "host", text: "Host Panel" },
    { id: "addHost", text: "Add Host" },
  ];

  const buttonViews = () => {
    return buttonDetails.map((input) => (
      <ButtonView
        key={input.id}
        text={input.text}
        variant="contained"
        color={selectedPanel === input.id ? muiColors.primary : muiColors.black}
        onClick={() => setSelectedPanel(input.id)}
        sx={{ mr: 2 }}
        size={isMobile ? "small" : undefined}
      />
    ));
  };

  return (
    <div style={styles.container}>
      <LabelView text="Admin Tools" type="smalltitle" />
      <div style={{ flex: 1, margin: "12px 0px" }}>
        {buttonViews()}
        <ButtonView
          id="manual-reschedule-button"
          text="Run Rescheduler"
          onClick={rescheduleEvents}
        />
      </div>

      <hr style={{ border: `2px solid ${blackColor}` }} />

      {selectedPanel === "host" && <AdminHostView />}
      {selectedPanel === "event" && <AdminEventView />}
      {selectedPanel === "addHost" && <AddHostView />}
    </div>
  );
}

const styles = {
  // This container style could possibly be moved up one level? Using same for EventFeedView
  container: {
    padding: 16,
    width: "100vw",
  },
};
